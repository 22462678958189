@import url('https://fonts.googleapis.com/css2?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

body {
  background-color: #012030;
  color: white;
  display: flex;
  justify-content: center;
  font-family: Oswald;
}

.flex {
  display: flex;
}


.topLine {
  font-family: Oswald;
  font-style: italic;
  font-size: 64px;
  text-align: center;
  padding: 20px 0 20px 0;
}

.mediumText { 
  font-size: 24px;
}


.textContainer {
  align-self: center;
  width: 70%;
}


.centerText {
  text-align: center;
}

.question {
  font-size: 20px;
  padding: 10px 0 10px 0;
  display: flex;  
  justify-content: space-between;
  flex-wrap: wrap;
}

.questionLabel {
  margin: 0 20px 0 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.form {
  width: 70%;
  align-self: center;
  flex-direction: column;
  display: flex;

}

@media screen and (min-width: 850px) {
  .form {
    min-height: 100vh;
  }
}

#root {
  width: 100%;
}

.submit {
  font-size: 32px;
  align-self: center;
  background-color: white;
  border-radius: 4px;
  border: white;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
  font-family: Oswald;
  width: 250px;
  margin-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
}

.submit:hover {
  background-color: gray;
}

.answer {
  width: 200px;
  padding: 2px;
  border: 2px;

}

.answerDropdown {
  width: 204px;
  padding: 2px;
  border: 2px;
}

.space {
  margin-bottom: 50px;
}

.response {
  border-radius: 2px;
  font-size: 18px;
  text-align: center;
  font-family: Oswald;
}

.score {
  text-align: center;
  font-family: Oswald;
  font-size: 250px !important;
  width: 40% !important;
  align-self: center;
  margin-bottom: 30px;
  min-width: 200px !important;
}

.scoreInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.scoreMeaning {
  max-width: 400px;
}

.why {
  background-color: #143e54;
  display: flex;
  flex-direction: column;
  position: relative;
}

#butWhy:hover {
  cursor: pointer
}

.buttons {
  display: flex;
  justify-content: space-around;
}


.formError {
  color: red;
  font-size: 18px;

}

#butWhy {
  position: absolute;
  margin: 30px 30px 0 0;
  right: 0;
}

a {
  text-decoration: none;
  color: white;
}

.field {
  display: flex;
  gap: 10px;
}


/* .CircularProgressbar-path {
  stroke: #3AFF66 !important;
} */
.CircularProgressbar-trail {
  stroke: #143E54 !important;
}
/* .CircularProgressbar-text {
  fill: #3AFF66 !important;
} */
.CircularProgressbar-background {
  fill: green;
}

.arrow-button {
  background-color: #f2f2f2;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  align-self: center;
  margin-bottom: 20px;
}

.bold {
  font-weight: bold
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #333;
}

.smallText  {
  margin: 50px 0 0 0;
}

#whyBlock {
  margin: 0 0 700px 0;
}

#citations {
  position: absolute;
  bottom: 20px;
}

.citation {
  margin: 10px 0 10px 0;
}


.progress-bar {
  align-self: center;
  width: 300px;
  height: 20px;
  border-radius: 5px;
  background-color: #143E54;
}

.progress-bar-fill {
  height: 100%;
  background-color: #538BA9;
  border-radius: 5px;
}

p {
  font-size: 20px;
}
